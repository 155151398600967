<template>
  <div class="configuration-container">
    <div class="configuration-header" v-show="this.$store.state.selectedAction !== 'configuration-view-all'">
        <h5>{{ title }}</h5>
    </div>
  </div>
</template>

<script>

export default {
  props: ['title'],
  components: {
  },
  methods: {
    save: function () {
      if (confirm('If you Click Ok, configuration changes will be made on this item, else click on Cancel to return to current configuration option')) {
        this.$store.state.selectedAction = 'view-all'
        this.$store.state.configurationViewAll = true
        this.$store.state.navigation[1].actions[0].visible = true
        this.$store.state.navigation[1].actions[1].visible = true
        this.$store.state.navigation[1].actions[2].visible = true
        this.$store.state.navigation[1].actions[3].visible = true
        this.$store.state.navigation[1].actions[4].visible = true
        this.$store.state.navigation[1].actions[5].visible = true
        this.$store.state.navigation[1].actions[6].visible = true
        this.$store.state.navigation[1].actions[7].visible = true
      } else {
        this.$store.state.configurationViewAll = false
      }
    },
    cancel: function () {
      if (confirm('If you Click Ok, configuration changes will be made on this item, else click on Cancel to return to View All and ignore changes')) {
        alert('cancelling')
        this.$store.state.selectedAction = 'view-all'
        this.$store.state.configurationViewAll = true
        this.$store.state.navigation[1].actions[0].visible = true
        this.$store.state.navigation[1].actions[1].visible = true
        this.$store.state.navigation[1].actions[2].visible = true
        this.$store.state.navigation[1].actions[3].visible = true
        this.$store.state.navigation[1].actions[4].visible = true
        this.$store.state.navigation[1].actions[5].visible = true
        this.$store.state.navigation[1].actions[6].visible = true
        this.$store.state.navigation[1].actions[7].visible = true
        this.$store.state.navigation[1].actions[8].visible = true
      } else {
        this.$store.state.configurationViewAll = false
      }
    }
  },
  data () {
    return {
      expandCollapsedText: 'Collapse',
      isCollapsed: false
    }
  }
}
</script>

<style scoped>

</style>
