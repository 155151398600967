<template>
  <div>
    <ConfigurationHeaderContainer title="PICOS criteria for Project X"></ConfigurationHeaderContainer>
    <FormEntryContainer title="Population" type="picoseditor"
      includevalue="<ul><li>Adult patients (&ge;18yrs) with advanced or metastatic NSCLC (AJCC stage IIIB, IIIC, or IV) who are previously untreated with systemic therapy for their advanced or metastatic disease (i.e., first-line) with PD-L1 expression tested<p align='left' style='line-height:normal;'><em>Subgroups of interest:</em></p>Histology: Non-squamous; squamous histology</li><li>PD-L1 expression level: &lt;1%; &ge;1%; 1-49%; &ge;50%; or other categories as reported (including unknown)</li><li>Patients without oncogene driver mutations</li><li>Smoking status: Current/former smoker; Never smoker</li><li>Eastern Cooperative Oncology Group performance status: ECOG=0; ECOG=1 </li><li>Ethnicity: Non-Asian; Asian</li><li>Race: white, non-white, other</li><li>Prior treatment experience: Newly diagnosed advanced; Progressed from lower stage to advanced stage</li><li>Disease stage: Metastatic; Non-metastatic (locally advanced)</li><li>Brain metastases at baseline: yes; no</li><li>Sex: males; females</li><li>Age: &lt;65 years; &ge;65 years</li></ul>"
      excludevalue="<ul><li>Pediatric patients (&lt;18yrs)</li><li>Patients previously treated with systemic therapy for their advanced or metastatic disease (i.e., second- or subsequent line)</li><li>Populations comprised of 100% patients with known oncogene driver mutations (e.g., EGFR, ALK, ROS-1)<sup>a</sup></li></ul>"></FormEntryContainer>
    <FormEntryContainer title="Interventions" type="picoseditor"
      includevalue="<ul><li>Immunotherapy monotherapies</li><li>Immunotherapies in combination with any chemotherapy listed below with/without bevacizumab:<ul style='list-style-type:circle;'><li>Platinum-based therapies (carboplatin or cisplatin) in combination with chemotherapy (docetaxel, gemcitabine, paclitaxel, pemetrexed, etoposide, or vinorelbine) with or without pemetrexed maintenance treatment</li><li>Non-platinum-based chemotherapy (i.e., gemcitabine) in combination with docetaxel or vinorelbine</li></ul></li><li>Immunotherapy in combination with another immunotherapy</li></ul>"
      excludevalue="<ul><li>Surgery</li><li>Radiotherapy</li><li>Any systemic therapy in combination with radiotherapy</li><li>Neoadjuvant regimens</li><li>Adjuvant regimens</li><li>Any regimens not including immunotherapy</li><li>Targeted therapy alone or in combination with chemotherapy:<ul style='list-style-type:circle;'><li>EGFR inhibitors (e.g., gefitinib, erlotinib, afatinib, dacomitinib, icotinib, osimertinib, necitumumab)</li><li>ALK inhibitors (e.g., crizotinib, alectinib, ceritinib)</li><li>BRAF inhibitors (e.g., dabrafenib, trametinib)</li><li>ROS-1 inhibitors (e.g., taletrectinib, brigatinib, entrectinib, crizotinib)</li></ul></li></ul>"></FormEntryContainer>
    <FormEntryContainer title="Comparators" type="picoseditor"
      includevalue="<ul><li>Any interventions of interest</li><li>Platinum-based therapies (carboplatin or cisplatin) in combination with chemotherapy (docetaxel, gemcitabine, paclitaxel, pemetrexed, etoposide, or vinorelbine), with or without pemetrexed maintenance treatment</li><li>Non-platinum-based chemotherapy (i.e., gemcitabine) in combination with docetaxel or vinorelbine</li><li>Bevacizumab in combination with any chemotherapy listed above</li><li>Placebo or best supportive care</li><li>Any treatment that facilitates an indirect comparison</li></ul>"
      excludevalue="<ul><li>Interventions or comparators other than those explicitly listed in the PICOS</li></ul>"></FormEntryContainer>
    <FormEntryContainer title="Outcomes" type="picoseditor"
      includevalue="<p align='left' style='line-height:normal;'>At least one of the following outcomes:</p><ul><li>Efficacy outcomes<ul style='list-style-type:circle;'><li>Overall survival (OS)</li><li>Progression free survival or time to progression (PFS or TTP)</li><li>Time on treatment (ToT)</li><li>Overall response rate (ORR)</li><li>Duration of response (DOR)</li><li>Time to tumor response (TTR)</li><li>Extended response duration<ul><li>Safety outcomes</li></ul></li><li>Immune-mediated AEs (IMAEs)</li><li>Grade 3 to 5 AEs (any or specific)</li><li>Discontinuation due to AEs</li><li>All-cause mortality</li><li>Health-related quality of life (HRQoL)<sup style='font-family:Arial, sans-serif;'>e</sup></li></ul></li></ul>"
      excludevalue="Outcomes other than those explicitly listed in the PICOS"></FormEntryContainer>
    <FormEntryContainer title="Study Design" type="picoseditor"
      includevalue="<ul><li>Randomized controlled trials (RCTs), phase II or III</li></ul>"
      excludevalue="<ul><li>Phase 0, I, or IV trials</li><li>Non-randomized clinical trials</li><li>Observational studies</li><li>Single-arm studies</li><li>Pooled analyses of RCTs</li><li>Case reports, case series</li><li>Letters, editorials, press releases, narrative reviews, opinion pieces, etc.</li></ul>"></FormEntryContainer>
    <FormEntryContainer title="Language" type="picostextarea" add="true" remove="true"
    includevalue="English language papers"
    excludevalue="Non-English papers (even if abstract is in English)"></FormEntryContainer>
    <kendo-button>Add Criteria</kendo-button><kendo-button>Save Changes</kendo-button>
  </div>
</template>

<script>
import ConfigurationHeaderContainer from '@/containers/ConfigurationHeaderContainer'
import FormEntryContainer from '@/containers/FormEntryContainer'

export default {
  name: 'data-entry-options',
  components: {
    ConfigurationHeaderContainer,
    FormEntryContainer
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
